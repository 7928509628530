var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"tag-editor",attrs:{"width":600,"visible":_vm.visible,"title":_vm.type ? _vm.$t('notice.' + _vm.type) : '',"maskClosable":false,"okButtonProps":{ props: { loading: _vm.saveLoading } },"centered":"","destroyOnClose":""},on:{"cancel":_vm.closeModal,"ok":_vm.saveTag}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.tagEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.cnName'),"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'cnName',
          {
            rules: [
              {
                validator: _vm.validateName,
              } ],
            initialValue: '',
          } ]),expression:"[\n          'cnName',\n          {\n            rules: [\n              {\n                validator: validateName,\n              },\n            ],\n            initialValue: '',\n          },\n        ]"}],attrs:{"placeholder":_vm.$t('common.enter')}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.enName'),"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'enName',
          {
            rules: [
              {
                validator: _vm.validateName,
              } ],
            initialValue: '',
          } ]),expression:"[\n          'enName',\n          {\n            rules: [\n              {\n                validator: validateName,\n              },\n            ],\n            initialValue: '',\n          },\n        ]"}],attrs:{"placeholder":_vm.$t('common.enter')}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('subject.color'),"required":"","validate-status":_vm.colorStatus,"help":_vm.$t('notice.selectColor')}},[_c('a-button-group',[_c('a-button',{staticStyle:{"padding":"0","width":"56px"},style:({ backgroundColor: _vm.color }),attrs:{"disabled":""}}),_c('a-button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.pickerShow = false); }),expression:"() => (pickerShow = false)"}],staticStyle:{"padding":"0","width":"32px","position":"relative"},on:{"click":function($event){_vm.pickerShow = true}}},[(_vm.pickerShow)?_c('chrome',{staticClass:"chorme-picker",attrs:{"value":_vm.color},on:{"input":_vm.updateValue}}):_vm._e(),_c('img',{staticClass:"color-icon",attrs:{"src":require("@/assets/images/subject/color.png")}})],1)],1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('notice.applyType')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'typeIds',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('notice.selectTypes'),
              } ],
            initialValue: [],
          } ]),expression:"[\n          'typeIds',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('notice.selectTypes'),\n              },\n            ],\n            initialValue: [],\n          },\n        ]"}],attrs:{"mode":"multiple","showArrow":"","optionFilterProp":"children","placeholder":_vm.$t('common.select')}},_vm._l((_vm.noticeTypeList),function(item){return _c('a-select-option',{key:item.code + ''},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.value : item.enValue || item.value))])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }